import React from "react";
import Section from "../../components/Section/Section";
import PostItem from "../../components/PostItem/PostItem";
import CardCursor from "../../components/CardCursor/CardCursor";

import "./PostFeed.scss";

const PostFeed = ({ posts }) => {
  return (
    <>
      <Section classes="post-feed">
        <div className="post-feed__row">
          <CardCursor label="Read" targetCard=".zoom_image__image">
            <div className={`post-feed__feed `}>
              {posts?.nodes ? (
                posts.nodes.map((post, i) => {
                  const index = i + 1;
                  return (
                    <PostItem key={post.databaseId} index={index} post={post} />
                  );
                })
              ) : (
                <p className="post-feed__not-found">No posts Found</p>
              )}
            </div>
          </CardCursor>
        </div>
      </Section>
    </>
  );
};

export default PostFeed;
