import { graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";
import Nav from "../components/Nav/Nav";
import Intro from "../modules/Intro/Intro";
import ProjectFeed from "../modules/ProjectFeed/ProjectFeed";
import PostFeed from "../modules/PostFeed/PostFeed";
import Section from "../components/Section/Section";
// import Footer from "../modules/Footer/Footer";
import PageFooter from "../modules/PageFooter/PageFooter";
import { register } from "swiper/element/bundle";

const ProjectPage = ({
  data: {
    wp: {
      themeOptions: {
        themeOptions: { storiesHeading },
      },
    },
    wpPage,
    allWpProject,
    allWpPost,
    allWpPage,
  },
}) => {
  const [pageState, setPageState] = useState("stories");
  const [type, setType] = useState("projects");
  const {
    template: { projectsTemplate },
    title,
    slug,
    seo,
  } = wpPage;
  const pageTitle = title ? `${title} -` : "";

  const homePage = allWpPage.nodes[0].template.pageModules.modules.find(
    (module) =>
      module.fieldGroupName === "Template_Modules_Pagemodules_Modules_Intro"
  );

  register();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const perspectivesParam = urlParams.get("perspectives");

    if (perspectivesParam === "true") {
      setType("posts");
    }
  }, []);

  useEffect(() => {
    setTimeout(function () {
      window.scroll.update();
    }, 1);
  }, [type]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle} Seesaw Studio</title>
      </Helmet>

      <Seo seo={seo} />
      <Nav position="top" slug={slug} templateType="stories" />
      <Intro
        classes=" project-intro"
        pageState={pageState}
        setPageState={setPageState}
        imageLeft={homePage.imageLeft}
        imageRight={homePage.imageRight}
      />
      <Section classes="stories-header">
        <div className="stories-header__column">
          {storiesHeading && (
            <h4
              className="feed-page__heading"
              dangerouslySetInnerHTML={{ __html: storiesHeading }}
            />
          )}

          <div className="stories-header__buttons">
            <button
              className={`button button--light${
                type === "projects" ? " active" : ""
              }`}
              onClick={() => setType("projects")}
            >
              Projects
            </button>

            <button
              className={`button button--light${
                type === "posts" ? " active" : ""
              }`}
              onClick={() => setType("posts")}
            >
              Perspectives
            </button>
          </div>
        </div>
      </Section>
      {type === "projects" ? (
        <ProjectFeed projects={allWpProject} />
      ) : (
        <PostFeed posts={allWpPost} />
      )}

      <PageFooter pageState={"stories"} slug="/" label="Studio" />

      {/* <Footer data={allWpPage} /> */}
    </>
  );
};

export default ProjectPage;

export const query = graphql`
  query ($id: String!) {
    wp {
      themeOptions {
        themeOptions {
          storiesHeading
        }
      }
    }
    allWpPost(sort: { fields: dateGmt, order: DESC }) {
      nodes {
        databaseId
        title
        excerpt
        uri
        date(formatString: "DD-MM-YY")
        postModules {
          readingTime
        }
        featuredImage {
          node {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      slug
      title
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      template {
        ... on WpTemplate_ProjectsWork {
          templateName
          projectsTemplate {
            heading
            text1
            text2
          }
        }
      }
    }
    allWpProject(sort: { fields: menuOrder, order: ASC }) {
      nodes {
        menuOrder
        databaseId
        title
        slug
        excerpt
        featuredImage {
          node {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        projectVideos {
          videoThumbnailLandscape
          videoThumbnailPortrait
        }
        landscapeImage {
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        projectClients {
          nodes {
            slug
            name
          }
        }
        roles {
          nodes {
            slug
          }
        }
        types {
          nodes {
            slug
          }
        }
        projectsSide {
          featuredProject
        }
      }
    }
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      nodes {
        template {
          ... on WpTemplate_Modules {
            templateName
            pageModules {
              modules {
                ... on WpTemplate_Modules_Pagemodules_Modules_Intro {
                  fieldGroupName
                  imageLeft {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                        )
                      }
                    }
                    altText
                  }
                  imageRight {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                        )
                      }
                    }
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
