import React, { useRef, useState, useEffect } from "react";
import gsap from "gsap";

import "./CardCursor.scss";

const CardCursor = ({ label, targetCard, children }) => {
  const cursorWrapperRef = useRef(null);
  const cursorRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ left: 0, top: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [scrollY, setScrollY] = useState(0);
  let parentY = 0;
  let parentX;

  let xDTo = gsap.quickTo(cursorRef.current, "left", {
    id: "xCTo",
    duration: 0.6,
    ease: "power3",
  });

  let yDTo = gsap.quickTo(cursorRef.current, "top", {
    id: "yCTo",
    duration: 0.6,
    ease: "power3",
  });

  useEffect(() => {
    if (!cursorRef.current) return;
    setOffsets();

    window.scroll.on("scroll", (args) => {
      if (!isVisible) return;
      setOffsets();
    });

    return () => {
      window.scroll.off("scroll");
    };
  }, [cursorRef, isVisible]);

  useEffect(() => {
    if (!isVisible) return;

    yDTo(cursorPosition.top - scrollY);
  }, [scrollY]);

  function setOffsets() {
    parentY = cursorWrapperRef.current?.getBoundingClientRect().top || 0;
    parentX = cursorWrapperRef.current?.getBoundingClientRect().left || 0;

    setOffset({
      x: parentX,
      y: parentY,
    });
  }

  function mouseMove(e) {
    const hasTargetAsParent = e.target.closest(targetCard);

    if (hasTargetAsParent) {
      setIsVisible(true);
    } else {
      setIsVisible(false);

      return false;
    }

    setCursorPosition({
      left: e.clientX,
      top: e.clientY,
    });

    xDTo(e.clientX - offset.x);
    yDTo(e.clientY - offset.y);
  }

  return (
    <div
      ref={cursorWrapperRef}
      className="card-cursor-wrapper"
      onMouseMove={mouseMove}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div
        ref={cursorRef}
        className={`card-cursor${isVisible ? " visible" : ""}`}
      >
        <span className="card-cursor__label">{label}</span>
      </div>

      {children}
    </div>
  );
};

export default CardCursor;
