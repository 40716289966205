import React, { useRef, useEffect, useState } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";
import ZoomImage from "../ZoomImage/ZoomImage";

import "./ProjectItem.scss";

const ProjectItem = ({ project, index }) => {
  const imageRef = useRef(null);
  const itemRef = useRef(null);
  const [speed, setSpeed] = useState("0");
  const aspectRatio = calculateAspectRatio(index);

  function calculateAspectRatio(index) {
    if ([0, 1, 5, 9].includes(index % 9)) {
      return "landscape";
    } else if ([2, 3, 4, 6, 7, 8].includes(index % 9)) {
      return "portrait";
    }
  }

  function handleClick() {
    itemRef.current.querySelector("a").click();
  }

  return (
    <div
      ref={itemRef}
      className="project-item project-feed__feed-item"
      key={project.id}
      onClick={handleClick}
    >
      <AniLink
        cover
        duration={1.5}
        direction="right"
        bg="#f2f3f0"
        to={`/projects/${project.slug}`}
      />
      <div className="project-item__wrapper">
        <div ref={imageRef} className={`project-item__image ${aspectRatio}`}>
          {project?.landscapeImage?.image && aspectRatio === "landscape" ? (
            <ZoomImage
              image={project.landscapeImage?.image}
              alt={project.landscapeImage?.image?.altText}
              video={project?.projectVideos?.videoThumbnailLandscape}
              title={project.title}
              excerpt={project.excerpt}
              scrollSpeed={speed}
              orientation="landscape"
              pannable={true}
            />
          ) : project.featuredImage?.node ? (
            <ZoomImage
              image={project.featuredImage?.node}
              alt={project.featuredImage?.node?.altText}
              video={project?.projectVideos?.videoThumbnailPortrait}
              title={project.title}
              excerpt={project.excerpt}
              scrollSpeed={speed}
              orientation="portrait"
              pannable={true}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
