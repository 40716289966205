import React, { useRef, useEffect, useState } from "react";
import ZoomImage from "../ZoomImage/ZoomImage";
import ChriateImage from "../../components/ChriateImage";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

import "./PostItem.scss";

const PostItem = ({ post, index }) => {
  const itemRef = useRef(null);
  const [speed, setSpeed] = useState("0");
  const date = post?.date ? post.date.replace(/-/g, ".") : false;
  const readingTime = post?.postModules?.readingTime
    ? post.postModules.readingTime
    : false;

  function handleClick() {
    itemRef.current.querySelector("a").click();
  }

  return (
    <div
      ref={itemRef}
      className="post-item post-feed__feed-item"
      key={post.id}
      onClick={handleClick}
    >
      <AniLink
        direction="right"
        cover
        to={`/posts${post.uri}`}
        duration={1.5}
        bg="#f2f3f0"
      />

      <div className="post-item__link">
        <div className={`post-item__image`}>
          {post?.featuredImage ? (
            <ZoomImage
              image={post.featuredImage?.node}
              alt={post.featuredImage?.node?.altText}
              scrollSpeed={speed}
              pannable={true}
            />
          ) : (
            ""
          )}
        </div>
        <div className="post-item__row">
          {date && <span className="post-item__date">{date}</span>}

          {readingTime && (
            <span className="post-item__reading-time">
              {readingTime} min read
            </span>
          )}
        </div>

        <div className="post-item__row">
          <h4 className="post-item__title">{post.title}</h4>
          {post.excerpt && (
            <div
              className="post-item__excerpt"
              dangerouslySetInnerHTML={{ __html: post.excerpt }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PostItem;
